import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import routes from './routes.js';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNavbar from "./components/Nav/TopNavbar";
import Footer from "./components/Sections/Footer"
export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
      </Helmet>
      {/* <Router> */}
      <Router>
        {/* <Landing /> */}
        <TopNavbar/>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} exact element={<route.component/>} />
        ))}
      </Routes>
      <Footer/>
      </Router>
    {/* </Router> */}
   



    </>
  );
}

