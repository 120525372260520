import React from "react";
import styled from "styled-components";
// Components
// import BlogBox from "../Elements/BlogBox";
// import FullButton from "../Buttons/FullButton";
import AboutImage from '../../assets/img/about-us/about-us.jpg'

export default function Blog() {
  return (
    <Wrapper id="about-us" className="container">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold text-center heading">About Us</h1>
            <p className="font18">
            R S Logistics was established in the year 2017, aimed at distinct logistical and
transportation solutions for its clients across India. It is committed to delivering
shipments on time, and in good shape using synchronized processes.
We offer the most efficient logistics and transportation services and solutions
through railways and surface express. We work with professionals united by
the passion for logistics with our solutions connecting businesses with people.
Providing end-to-end logistics solutions to our clients across various verticals
within and in an unmatched transit time is what we are known best for. We
take pride in our network operations spread throughout India.
On-time delivery, safety &amp; security of the cargo, trustworthiness, and client
satisfaction make us stand out from the others in the logistics industry.
            </p>
          </HeaderInfo>
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
             
            <h3 className="font20 extraBold">Why Choose Us?</h3>
            <ListStyle>
              <li>Own Vehicles</li>
              <li>Secure Drivers</li>
              <li>On-time Delivery</li>
              <li>WhatsApp Alerts</li>
              <li>24/7 Service</li>
            </ListStyle>
            </div>
          </div>
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <img src={AboutImage} width={'100%'} height={'500px'} alt="error while loading" loading="lazy"/>
              </div>
            </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
padding-top: 80px;
width: 100%;
min-height: 840px;
// max-width: 1220px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ListStyle = styled.ul`
  list-style: disc;
  list-style-position: inside;
  // font-size: 0.813rem;
  font-size: 1.125rem
`;