import React from "react";
import styled from "styled-components";


// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useNavigate} from 'react-router-dom';
import HeaderImage from "../../assets/img/rs logistics p (2).png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {

  const navigate = useNavigate();

  const toComponentB=(client)=>{
      toggleSidebar(!sidebarOpen);
      navigate('/our-clients',{state:client});
  }
  return (
    <Wrapper className="animate whiteBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter sidebar-menu">
          {/* <LogoIcon /> */}

          <Img className="" src={HeaderImage} alt="office" style={{zIndex: 9, width:'85px', marginTop:'10px',mixBlendMode:'multiply'}} />
          {/* <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            RS Logistics
          </h1> */}
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn sidebar">
        <li className="semiBold font15 pointer">
        <Link activeclass="active" onClick={() => toggleSidebar(!sidebarOpen)} style={{ padding: "10px 15px" }} to="/" offset={-80}>
                Home
              </Link>
        </li>
        <li className="semiBold font15 pointer">
        <Link activeclass="active" onClick={() => toggleSidebar(!sidebarOpen)} style={{ padding: "10px 15px" }} to="/about" offset={-80}>
                About Us
              </Link>
        </li>
        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeclass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Key Features
          </Link>
        </li> */}
         <NavDropdown title="Our Services" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{toComponentB({id:1,name:'Rail Freight'})}} >
                Rail Freight
                </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{toComponentB({id:2,name:'Surface Express'})}}>
              Surface Express
              </NavDropdown.Item>
            </NavDropdown>

        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeclass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Pricing
          </Link>
        </li> */}
        <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/contact" offset={-80} onClick={() => toggleSidebar(!sidebarOpen)}>
                Contact
              </Link>
            </li>
      </UlStyle>
      {/* <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <a href="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
            Log in
          </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Get Started
          </a>
        </li>
      </UlStyle> */}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  background-color: #edcead;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;



const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;