import React from "react";
import styled from "styled-components";
// Components
// import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/vehicles-boxes-supply-chain.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import ProjectBox from "../Elements/ProjectBox";
import ProjectImg4 from "../../assets/img/projects/Railway logistics.png";
import ProjectImg5 from "../../assets/img/projects/Surface Express.png";
import {useNavigate} from 'react-router-dom';
import ClientSlider from '../Elements/ClientSlider';
export default function Header() {

  const navigate = useNavigate();

  const toComponentB=(client)=>{
      navigate('/our-clients',{state:client});
  }

  return (
    <><Wrapper id="home" className="container flexSpaceCenter reveal fade-left home">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">End-to-end Logistics Service
            Provider</h1>
          <HeaderP className="font13 font18">
            <p>
            Established in the year 2017, R S Logistics is an end-to-end logistics service
            provider of various clients&#39; cargo via rail and surface express. We have the best
            services in the market at a competitive rate. We believe in providing quality
            services and that’s what we strive for, ensuring safety and timely commitment.

            </p>
          </HeaderP>
          {/* <BtnWrapper>
      <FullButton title="Get Started" />
    </BtnWrapper> */}
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, width: '426px' }} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>The goal of logistics is to meet customer needs at the right place, at the right time, and at the right price.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{ marginTop: '10px' }}>Larry Zimmerman</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>


    {/* <OtherWrapper id="about-us" className="container">
      <div className="whiteBg" style={{ padding: "40px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold text-center">About Us</h1>
            <p className="font18">
            R S Logistics was established in the year 2017, aimed at distinct logistical and
transportation solutions for its clients across India. It is committed to delivering
shipments on time, and in good shape using synchronized processes.
We offer the most efficient logistics and transportation services and solutions
through railways and surface express. We work with professionals united by
the passion for logistics with our solutions connecting businesses with people.
Providing end-to-end logistics solutions to our clients across various verticals
within and in an unmatched transit time is what we are known best for. We
take pride in our network operations spread throughout India.
On-time delivery, safety &amp; security of the cargo, trustworthiness, and client
satisfaction make us stand out from the others in the logistics industry.
            </p>
          </HeaderInfo>
          <div className="row ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
             
            <h3 className="font20 extraBold">Why Choose Us?</h3>
            <ListStyle>
              <li>Own Vehicles</li>
              <li>Secure Drivers</li>
              <li>On-time Delivery</li>
              <li>WhatsApp Alerts</li>
              <li>24/7 Service</li>

            </ListStyle>

            
       
            </div>
         
          </div>

        </div>
      </div>

    </OtherWrapper> */}


    <OtherWrapper id="projects" className="container">
      <div className="whiteBg" style={{ padding: "40px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold text-center heading">Our Services</h1>
            <p className="font18 mt-3">
            Over the years, rail freight transport in India has become quite
            popular mainly for two reasons, it is economical and fast. India has
            the fourth largest network of railways in the globe. We provide the
            logistics services through the biggest transportation source. When
            every network and reach is made easy with rail, why not use the
            advantage of it to let us reach your doorstep earlier and more safely
            than other freights?
            </p>
          </HeaderInfo>

          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg4}
                title="Rail Freight"
                text=""
                action={()=>{toComponentB({id:1,name:'Rail Freight'})}}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg5}
                title="Surface Express"
                text=""
                action={()=>{toComponentB({id:2,name:'Surface Express'})}}
              />
            </div>

          </div>
        </div>
      </div>

      

    </OtherWrapper>

    <OtherWrapper id="about-us" className="container">
      <div className="whiteBg" style={{ padding: "40px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold text-center heading">Our Clients</h1>
          </HeaderInfo>
          {/* <div className="lightBg container" > */}
            <ClientSlider />
          {/* </div> */}


        </div>
      </div>

    </OtherWrapper>


    </>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  max-width: 1220px;
  // margin: 80px 0px 0px 0px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const OtherWrapper = styled.section`
// padding-top: 80px;
width: 100%;
// min-height: 840px;
max-width: 1220px;
// margin: 80px 0px 0px 0px;
@media (max-width: 960px) {
  flex-direction: column;
}
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 517px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
// const BtnWrapper = styled.div`
//   max-width: 190px;
//   @media (max-width: 960px) {
//     margin: 0 auto;
//   }
// `;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
// const ListStyle = styled.ul`
//   list-style: disc;
//   list-style-position: inside;
//   font-size: 1.125rem
// `;





