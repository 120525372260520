import React from 'react';
// import './YourComponent.css'; // Import your CSS file if it's separate
import styled from "styled-components";
// import FullButton from "../Buttons/FullButton";
import {useNavigate} from 'react-router-dom';
export default function CTAComponent(props) {

  const navigate = useNavigate();

  const goContact=()=>{
      navigate('/contact');
  }

  return (
    <Wrapper className="container flexSpaceCenter cta-section reveal fade-left">
      <LeftSide>
          <HeaderP className="font20 extraBold">
                {props.title}
          </HeaderP>
      </LeftSide>

      <RightSide className="flexCenter">
          <BtnWrapper>
            <ButtonWrapper
                className="animate pointer radius8 cta-button"
                onClick={goContact}>
               {props.buttonText}
                </ButtonWrapper>
          </BtnWrapper>
      </RightSide>
    </Wrapper>
  );
}



const ButtonWrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#ffff")};
  background-color: ${(props) => (props.border ? "transparent" : "transparent")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#580cd2")};
    border: 1px solid #343a40;
    color: ${(props) => (props.border ? "#343a40" : "#fff")};
  }
`;

const Wrapper = styled.section`
    background: #a98b20;
    color: #ffff;
    border-radius: 10px;

  padding-top: 10px;
  width: 100%;
//   min-height: 840px;
  // margin: 80px 0px 0px 0px;
  @media (max-width: 960px) {
    flex-direction: row-reverse;
  }
`;
// const CenterAlign = styled.div`
//   width: 100%;
//   height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 2;
//     margin: 50px 0;
//     text-align: center;
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
// `;


const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin:0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: end;
  margin-right: 12px;

  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    // margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  // max-width: 470px;
  padding: 20px;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 20px;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  margin-bottom: 10px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
// const GreyDiv = styled.div`
//   width: 30%;
//   height: 700px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 0;
//   @media (max-width: 960px) {
//     display: none;
//   }
// `;
// const ImageWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   position: relative;
//   z-index: 9;
//   @media (max-width: 960px) {
//     width: 100%;
//     justify-content: center;
//   }
// `;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;

