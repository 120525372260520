import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-us/contact-1.jpg";
import ContactImg2 from "../../assets/img/contact-us/180-195.jpg";
import ContactImg3 from "../../assets/img/contact-us/278-330.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
  const form = useRef();



  const [inputFields, setInputFields] = useState({
    name: "",
    user_email: "",
    subject: '',
  });

  const [isEmailSend, setEmailSend] = useState(false);

  const validateValues = (inputValues) => {
    if (!inputValues.name || !inputValues.user_email || !inputValues.subject ) {
      return false;
    }else{
      return true;
    }
  
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const isFormValid = validateValues(inputFields)
    if(!isFormValid){
      return;
    }else{
    emailjs.sendForm('service_zu9jadi', 'template_qfx1gyl', form.current, '88KSp6F_gfJBTdCgr')
      .then((result) => {
          console.log(result.text);
          if(result.text){
            setEmailSend(false);
            toast("Your query has been raised!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type:'success',
              });
          }
      }, (error) => {
          console.log(error.text);
          setEmailSend(false);
          toast("Something went wrong! Please try after some time",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type:'error',
            });
      });
    }
  };


  return (
    <Wrapper id="contact" className="container">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font18">
             Have any questions? Call us or fill out the form below. 
              <br />
              We will get back to you within 1 business day.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form ref={form}>
                <label className="font13">First name:</label>
                <input type="text" id="name" name="name" className="font20 extraBold"  onChange={handleChange} value={inputFields.name} required/>
                <label className="font13">Email:</label>
                <input type="text" id="user_email" name="user_email" className="font20 extraBold"  onChange={handleChange} value={inputFields.user_email} required/>
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold"  onChange={handleChange} value={inputFields.subject} required/>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" required/>
              </Form>
              <SumbitWrapper className="flex">
              { isEmailSend ? 
                   <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} disabled/>
                    
                    : 
                    // <Button variant="primary" type="submit" onClick={sendEmail}>Submit</Button>
                   <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={sendEmail}/>
                  }
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex contact_images">
              {/* <div className="row contact_images"> */}
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
                  <div  className="flexNullCenter flexColumn">
                    <ContactImgBox>
                      <img src={ContactImg1} alt="office" className="radius6" />
                    </ContactImgBox>
                    <ContactImgBox>
                      <img src={ContactImg2} alt="office" className="radius6" />
                    </ContactImgBox>
                  </div>

                {/* </div> */}

                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex"> */}
              <div style={{ width: "50%" }} className="contact-us-last-image">
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" loading="lazy"/>
                </div>
              </div>

                {/* </div> */}

              {/* </div> */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
padding-top: 80px;
width: 100%;
min-height: 840px;
// max-width: 1220px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  // align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









