// // routes.js
// import Home from './components/Home';
// import About from './components/About';
// import Contact from './components/Contact';



// Sections
import Header from "./components/Sections/Header"; //Home
import Blog from "./components/Sections/Blog"; //About us
import Services from "./components/Sections/Services"; // feature
import Projects from "./components/Sections/Projects";
// import Pricing from "../components/Sections/Pricing";
import Contact from "./components/Sections/Contact";





const routes = [
  { path: '/', component: Header },
  { path: '/about', component: Blog },
  { path: '/feature', component: Services },
  { path: '/our-clients', component: Projects},
  { path: '/contact', component: Contact}
];

export default routes;