import React from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
// Assets

import HeaderImage from "../../assets/img/rs logistics p (2).png";

import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useNavigate} from 'react-router-dom';

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }


  const navigate = useNavigate();

  const toComponentB=(client)=>{
      navigate('/our-clients',{state:client});
  }

  return (
    <Wrapper >
      <div className="footerBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="/"  offset={-80}>
            <Img className="" src={HeaderImage} alt="office" style={{zIndex: 9, width:'140px',mixBlendMode:'multiply'}} />
              {/* <LogoImg />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Lorem Ipsum Footer
              </h1> */}
            </Link>


            <UlWrapper className="footerNav">
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/" offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/about" offset={-80}>
                About Us
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/feature"  offset={-80}>
                Key Features
              </Link>
            </li> */}

            <NavDropdown title="Our Services" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{toComponentB({id:1,name:'Rail Freight'})}}>
                Rail Freight
                </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{toComponentB({id:2,name:'Surface Express'})}}>
              Surface Express
              </NavDropdown.Item>
            </NavDropdown>
            
            {/* <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/our-clients" offset={-80}>
                Our Services
              </Link>
            </li> */}
          
            
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/contact" offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>

          <div>
          <StyleAddress className="themeColor font13 ">
            <h1 className="font20">Address</h1>
            <span>MIG 50, NEW LDA COLONY, AISHBAGH</span> <br/>
            <span>LUCKNOW, UTTAR PRADESH</span><br/>
            <span>Pincode : 226004</span>


            <div className="logo-container-footer">

                <h1 style={{ marginTop: "15px" }} className="semiBold font15">
                <i className="fa fa-phone" aria-hidden="true"></i> : <a href="tel:8527917044">+91 8527917044</a>,   <a href="tel:9517018099">+91 9517018099</a>
                </h1>    
                  <h1 className="semiBold font15">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i> : <a href="mailto:sumitKhare@gmail.com" target="_blank" rel="noreferrer">skhr1492@gmail.com</a> 
                </h1>
                </div>

            </StyleAddress>
          </div>
            {/* <Link className="whiteColor animate pointer font13" to="/"  offset={-80}>
              Back to top
            </Link> */}
          </InnerWrapper>
          <StyleP className="themeColor font13 text-center">
              © {getCurrentYear()} - <span className="purpleColor font13">R S Logistics</span> | All Right Reserved
            </StyleP>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  // position: fixed;
  //   left: 0px;
  //   bottom: 0px;
  //   /* height: 30px; */
  //   width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
margin-bottom: 0px;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const StyleAddress = styled.div`
margin-bottom: 0px;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;


const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;