import React from "react";
import styled from "styled-components";
import CTAComponent from '../Elements/Cta';
import {useLocation} from 'react-router-dom';
// import AddImage2 from "../../assets/img/add/add2.png";

export default function Projects() {

  

  const location = useLocation();
  console.log("location", location)
  return (
    <Wrapper id="projects" className="container">
      <div className="whiteBg">
        <div className="container">
          {/* <HeaderInfo>
            <h1 className="font40 extraBold text-center">Our Services</h1>
            <p className="font18">
            Over the years, rail freight transport in India has become quite
            popular mainly for two reasons, it is economical and fast. India has
            the fourth largest network of railways in the globe. We provide the
            logistics services through the biggest transportation source. When
            every network and reach is made easy with rail, why not use the
            advantage of it to let us reach your doorstep earlier and more safely
            than other freights?
            </p>
          </HeaderInfo> */}

         <div className="whiteBg" style={{padding: '50px 0'}}>
        <div className="container">

          {location.state.id === 1 ?
          
          <><HeaderInfo>
                  {/* <h1 className="font20 extraBold">Rail Freight</h1> */}
                  <h1 className="font40 extraBold text-center heading">Rail Freight</h1>
                  <p className="font18">
                    Rail freight transportation is faster, more reliable, and environment-
                    friendly than ocean or air freight. It involves the use of railroads and
                    trains to transport cargo via land. It can be used for entire journeys, or
                    to transport goods part of the way along a route where ocean or road
                    freight isn’t possible.
                    <br />
                    <br />
                    R S Logistics is an ideal choice for freight transportation from one
                    location to another by rail. As a reliable and experienced railway
                    logistics service provider in India that has been running for a long
                    time, we have the experience and confidence to make sure that the
                    consignments are delivered on time and safely to the desired
                    locations.
                    <br /><br />
                    We promise you a safe, economical, and on-time train delivery of
                    your shipment.
                  </p>
                  <br />
                </HeaderInfo>
                <HeaderInfo>
                    <h1 className="font20 extraBold">Our Key Features:</h1>
                    <ListStyle>
                      {/* <ul className="font13" > */}
                      <li>
                        <span>Freight monitoring</span>
                        </li>
                      <li><span>Damage-free & on-time delivery</span></li>
                      <li><span>Dedicated manpower to handle bulk and time-sensitive train cargo</span></li>
                      <li><span>Cost-effective service</span></li>
                      <li><span>Station-to-station delivery</span></li>
                      <li><span>Door-to-station delivery</span></li>
                      <li><span>Station-to-door delivery</span></li>
                      <li><span>Door-to-door delivery</span></li>

                      {/* </ul> */}

                    </ListStyle>

                    {/* <CTAComponent/> */}

                    <p>
                      {/* <br/>
  <strong>CTA- </strong> Looking for hassle-free rail freight services in India?
  <br/>
  <strong>CTA button- </strong>Contact Us */}
                    </p>

                    <br />
                  </HeaderInfo>

        <CTAComponent title="Looking for hassle-free rail freight services in India?" buttonText="Contact Us" /><br/> <br/>

                  
                  
                  </>

                  
          :
          <><HeaderInfo>
                  {/* <h1 className="font20 extraBold">Surface Express</h1> */}
                  <h1 className="font40 extraBold text-center heading">Surface Express</h1>

                  <p className="font18">
                    Surface express cargo is one of the most preferred modes of
                    transporting goods from one place to another via roadways. Express
                    delivers your goods on time with R S Logistics surface express
                    services.
                    <br />
                    <br />
                    Our Surface Freight Service includes the conveyance of consignment
                    from one place to another by rail or road route. This includes receipt
                    of goods at the desired location and onward dispatching of the
                    consignment to the final destination.
                    <br /><br />
                    We handle all the bulk consignments with ease without allowing any
                    damage to them as we have been doing since day one. We keep the
                    process easy and transparent which as our clients/customers
                    deserve and we aspire to keep our transit time as good as possible.
                  </p>
                  <br />
                </HeaderInfo><HeaderInfo>
                    <h1 className="font20 extraBold">Our Key Features:</h1>
                    <ListStyle>
                      {/* <ul className="font13" > */}
                      <li>Cost-effective solutions</li>
                      <li>Safe transportation</li>
                      <li>Dedicated staff</li>
                      <li>Safe packaging</li>
                      <li>On-time delivery</li>


                      {/* </ul> */}

                    </ListStyle>
                    <p>
                      {/* <br/>
    <strong>CTA- </strong> Looking for hassle-free rail freight services in India?
    <br/>
    <strong>CTA button- </strong> Contact Us */}
                    </p>
                    <br />
                  </HeaderInfo>
        <CTAComponent title="Experience swift and secure deliveries with our surface express
transportation services." buttonText="Contact Us" /> <br/> <br/>
                  </>



        }









          {/* <HeaderInfo>
            <h1 className="font20 extraBold">Our Clients</h1>
            <p className="font13">
            Our precious clients to whom we are offering comprehensive
logistics services.
              
            </p>
          </HeaderInfo> */}


          



          {/* <ClientSlider /> */}
        </div>
      </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>


      </div>
      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
padding-top: 80px;
width: 100%;
min-height: 840px;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    // text-align: center;
  }
`;

const ListStyle = styled.ul`
  list-style: disc;
  // list-style-position: inside;
  font-size: 1.125rem
`;
// const Advertising = styled.div`
//   padding: 100px 0;
//   margin: 100px 0;
//   position: relative;
//   @media (max-width: 1160px) {
//     padding: 60px 0 40px 0;
//   }
//   @media (max-width: 860px) {
//     flex-direction: column;
//     padding: 0 0 30px 0;
//     margin: 80px 0 0px 0;
//   }
// `;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
// const AddLeft = styled.div`
//   position: relative;
//   width: 50%;
//   p {
//     max-width: 475px;
//   }
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//     text-align: center;
//     h2 {
//       line-height: 3rem;
//       margin: 15px 0;
//     }
//     p {
//       margin: 0 auto;
//     }
//   }
// `;
// const AddRight = styled.div`
//   width: 50%;
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//   }
// `;
// const AddLeftInner = styled.div`
//   width: 100%;
//   position: absolute;
//   top: -300px;
//   left: 0;
//   @media (max-width: 1190px) {
//     top: -250px;
//   }
//   @media (max-width: 920px) {
//     top: -200px;
//   }
//   @media (max-width: 860px) {
//     order: 1;
//     position: relative;
//     top: -60px;
//     left: 0;
//   }
// `;
// const ImgWrapper = styled.div`
//   width: 100%;
//   padding: 0 15%;
//   img {
//     width: 100%;
//     height: auto;
//   }
//   @media (max-width: 400px) {
//     padding: 0;
//   }
// `;
