import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
// import LogoIcon from "../../assets/svg/Logo"
import BurgerIcon from "../../assets/svg/BurgerIcon";
import HeaderImage from "../../assets/img/rs logistics p (2).png";

import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, useNavigate} from 'react-router-dom';

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  const navigate = useNavigate();

  const toComponentB=(client)=>{
      navigate('/our-clients',{state:client});
  }



  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "100px" } : { height: "100px" }}>
        <NavInner className="container flexSpaceCenter navbar-container">
          <div className="logo-container">
          
          <Link className="pointer flexNullCenter" to="/" >
          <Img className="" src={HeaderImage} alt="office" style={{zIndex: 9, width:'140px', marginTop:'10px', mixBlendMode:'multiply'}} />

            {/* <LogoIcon /> */}
          </Link>

            <h1  className="semiBold font15 mt-1">
              GST No. : 09ABAFR9277P1Z7
            </h1>
          </div>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/" offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/about" offset={-80}>
                About Us
              </Link>
            </li>
            {/* <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/feature"  offset={-80}>
                Key Features
              </Link>
            </li> */}

            <NavDropdown title="Our Services" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{toComponentB({id:1,name:'Rail Freight'})}}>
                Rail Freight
                </NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{toComponentB({id:2,name:'Surface Express'})}}>
              Surface Express
              </NavDropdown.Item>
            </NavDropdown>
            
            {/* <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/our-clients" offset={-80}>
                Our Services
              </Link>
            </li> */}
          
            
            <li className="semiBold font15 pointer">
              <Link activeclass="active" style={{ padding: "10px 15px" }} to="/contact" offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>
          {/* <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
          </UlWrapperRight> */}

          <Contact>
          <div className="logo-container">

            <h1 style={{ marginTop: "15px" }} className="semiBold font15">
            <i className="fa fa-phone" aria-hidden="true"></i> : <a href="tel:8527917044">+91 8527917044</a>,   <a href="tel:9517018099">+91 9517018099</a>
            </h1>    
              <h1 className="semiBold font15">
              <i className="fa fa-envelope-o" aria-hidden="true"></i> : <a href="mailto:sumitKhare@gmail.com" target="_blank" rel="noreferrer">skhr1492@gmail.com</a> 
            </h1>
          </div>

          </Contact>

        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 80px;
  // background-color: #0B093B;
  background-color: #edcead;
  color: white;
}
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
  color: black !important;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;


const Contact = styled.ul`
  position: relative;
  display: block;
  @media (max-width: 760px) {
    display: none;
  }
`;


// const UlWrapperRight = styled.ul`
//   @media (max-width: 760px) {
//     display: none;
//   }
// `;


const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;